exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-ev-charging-network-js": () => import("./../../../src/pages/case-studies/ev-charging-network.js" /* webpackChunkName: "component---src-pages-case-studies-ev-charging-network-js" */),
  "component---src-pages-case-studies-international-marina-js": () => import("./../../../src/pages/case-studies/international-marina.js" /* webpackChunkName: "component---src-pages-case-studies-international-marina-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-osp-bulk-index-js": () => import("./../../../src/pages/custom/osp-bulk/index.js" /* webpackChunkName: "component---src-pages-custom-osp-bulk-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-our-capabilities-js": () => import("./../../../src/pages/our-capabilities.js" /* webpackChunkName: "component---src-pages-our-capabilities-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */)
}

